import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Title20, Title24, Title64 } from './styles/TextStyles'

const ProjectSectionCopy = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`
const ProjectSectionEyebrow = styled(Title24)`
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.medium};
  text-transform: uppercase;
  letter-spacing: 4px;

  @media (max-width: 800px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
`

const ProjectSectionTitle = styled(Title64)`
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.extraBold};
  margin: 0px;
  margin-bottom: 32px;

  @media (max-width: 800px) {
    font-size: 40px;
  }
`

const ProjectSectionText = styled(Title20)`
  color: ${themes.light.neutral7};
  font-weight: ${fontWeight.regular};
  margin: 0px;

  @media (max-width: 800px) {
    padding-bottom: 16px;
  }
`

const ProjectHeader = (props) => (
  <ProjectSectionCopy>
    <ProjectSectionEyebrow>{props.eyebrow}</ProjectSectionEyebrow>
    <ProjectSectionTitle>{props.title}</ProjectSectionTitle>
    <ProjectSectionText>{props.text}</ProjectSectionText>
  </ProjectSectionCopy>
)

export default ProjectHeader
