import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Title64, Title24 } from './styles/TextStyles'

const ProjectTitleGroup = styled.div`
  padding: 120px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 80px 0px;
  }

  @media (min-width: 2000px) {
    padding: 120px 640px 64px 640px;
  }
`

const ImgWrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
`

const ProjectTitleImg = styled.img`
  width: 100%;
  margin: 0 auto;
`

const ProjectTitleCopy = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 0px 32px;
  }
`

const ProjectTitleTitle = styled(Title64)`
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.bold};
  margin: 40px 0px 24px 0px;
  text-align: center;

  @media (max-width: 800px) {
    font-size: 48px;
    letter-spacing: -1px;
    margin: 40px 0px 24px 0px;
  }
`

const ProjectTitleText = styled(Title24)`
  color: ${themes.light.neutral7};
  margin: 0 0 40px 0;
  font-weight: ${fontWeight.regular};
  text-align: center;

  @media (max-width: 800px) {
    font-size: 24px;
    margin: 0 0 40px 0;
  }
`

const ProjectTitle = (props) => (
  <ProjectTitleGroup>
    <ProjectTitleCopy>
      <ProjectTitleTitle>{props.title}</ProjectTitleTitle>
      <ProjectTitleText>{props.text}</ProjectTitleText>
    </ProjectTitleCopy>
    <ImgWrapper>
      <ProjectTitleImg src={props.image} />
    </ImgWrapper>
  </ProjectTitleGroup>
)

export default ProjectTitle
