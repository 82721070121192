import React from 'react'
import ProjectTitle from '../../components/ProjectTitle'
import ProjectSectionData from '../../components/ProjectSectionData'
import ProjectSectionParagraphs from '../../components/ProjectSectionParagraphs'
import ProjectSectionLarger from '../../components/ProjectSectionLarger'
import { Slide } from '../styles/Animation'

const browsePage = () => (
  <div>
    <Slide delay={0.1}>
      <ProjectTitle
        image={require('../../images/browse.png').default}
        title="Peloton Browse"
        text="Making connected fitness more connected than ever."
      />
    </Slide>

    <Slide delay={0.2}>
      <ProjectSectionData
        eyebrow="The problem"
        hideProjectSectionNumberGroup="true"
        title="Peloton's browse system was reaching its breaking point."
        text="Between new hardware and new types of content, our existing system couldn't handle much more."
        number1="1"
        listTitle1="We had gone through a period of rapid growth in a relatively short time."
        listText1="Most of our infastructure was built around a single product (the Bike). But as the company evolved, so did our product offering. That system designed for one Bike could not be expected to work for an entire connected fitness ecosystem of hardware and apps."
        number2="2"
        listTitle2="Our content library was going through its own evolution independent of hardware."
        listText2='Our content team had a lofty goal to establish itself as the "Netflix of Fitness." This meant producing a multitude of new content mediums - both in volume and variety - which put our already limited system at its breaking point.'
        number3="3"
        listTitle3="The browse taxonomy was one-dimensional and not built properly to scale."
        listText3="The combination of new hardware mediums and content types expanded far past our original set of fitness disciplines. We were cramming more and more things into a finite space."
      />
    </Slide>

    <ProjectSectionParagraphs
      eyebrow="The objective"
      title="Design an entirely new browse taxonomy, and build an experience that supports it on across our suite of products."
      listTitle1="Flatten the taxonomy."
      listText1="Our existing infastructure was very rigid. There was no level of classification outside of Fitness Discipline. We wanted the flexibilty to create any collection we wanted."
      listTitle2="Develop new elements."
      listText2="We wanted to be able to group common classes together, link to related collection, and delineate which have set orders of classes."
      listTitle3="Unify our experiences."
      listText3="Our Bike and Tread experiences were the only ones with true homescreens. In order to achieve this we'd need a universal page structure."
    />

    <ProjectSectionLarger
      eyebrow="The solution"
      title="An entirely new way to find a class on any Peloton device."
      text="Bike, Tread, App, Tablet, TV, and anything else in the future."
      reasonTitle1="Flexibility first behind the scenes."
      reasonText1="An infastructure aimed at easily creating class collections and linking them to others."
      reasonVideo1={require('../../images/flow.mp4').default}
      reasonTitle2="A unified browse experience across our entire app ecosystem."
      reasonText2="Frictionlessly find a class on any device from a Tread to a phone."
      reasonImage2={require('../../images/showcase.png').default}
      reasonTitle3="A new model for classifying types of class groupings."
      reasonText3="A system of Collections and Series for content managers to deliberately create."
      reasonVideo3={require('../../images/series.mp4').default}
      reasonTitle4="Endless possibilities for the future."
      reasonText4="A system that can scale with time and new platforms."
      reasonImage4={require('../../images/cms.png').default}
    />
  </div>
)

export default browsePage
