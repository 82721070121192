import React from 'react'
import ProjectTitle from '../../components/ProjectTitle'
import ProjectSection from '../../components/ProjectSection'
import ProjectSectionData from '../../components/ProjectSectionData'
import ProjectSectionParagraphs from '../../components/ProjectSectionParagraphs'
import ProjectSectionLarger from '../../components/ProjectSectionLarger'
import { Slide } from '../styles/Animation'

const runwayPage = () => (
  <div>
    <Slide delay={0.1}>
      <ProjectTitle
        image={require('../../images/run.png').default}
        title="Stash Onboarding"
        text="Rethinking the first interaction with Stash."
      />
    </Slide>

    <Slide delay={0.2}>
      <ProjectSection
        eyebrow="The problem"
        title="Stash’s registration experience was inefficient. 😢"
        text="Simply put: you can’t make money if people aren’t joining."
        reasonTitle1="Long and tedious."
        reasonText1="Creating a Stash account took 11 minutes on median."
        reasonImage1={require('../../images/long.png').default}
        reasonTitle2="Untrustworthy."
        reasonText2="Qual research showed our our trust metric was alarmingly low."
        reasonImage2={require('../../images/trust.png').default}
        reasonTitle3="Overly verbose."
        reasonText3="Nearly every page felt like you were reading a book, and not a fun one at that."
        reasonImage3={require('../../images/words.png').default}
        reasonTitle4="Fragmented."
        reasonText4="With such a variety of screen types we created too much cognitive overload."
        reasonImage4={require('../../images/frag.png').default}
      />
    </Slide>

    <ProjectSectionData
      eyebrow="The data"
      numberColor={'red'}
      hoverColor={'red'}
      title="Several key points were leading to significant dropoff. 👀"
      text="We knew the areas we wanted to target."
      image={require('../../images/run.png').default}
      numberTitle1="55%"
      numberText1="didn't create an account."
      numberTitle2="30%"
      numberText2="didn't enter their SSN."
      numberTitle3="28%"
      numberText3="didn't pay for a subscription."
      number1="1"
      listTitle1="Onboarding is a funnel."
      listText1="Nearly all business metrics are tied to having users on the platform. If they're not joining from the onset, we're not making money."
      number2="2"
      listTitle2="We're a licensed fiduciary."
      listText2="A contingency of being able to provide financial guidance is that we are required to ask sensitive information. You can guess where we see most of our dropoff."
      number3="3"
      listTitle3="Stash is a subscription business."
      listText3="We have several revenue streams, but the largest and most consistent is subscription fees collected by users."
    />

    <ProjectSectionParagraphs
      eyebrow="The challenge"
      title="We had a lot to overcome. 🏃‍♂️"
      text="Big changes require big commitment."
      listTitle1="Our visual language."
      listText1="We were proposing a lot of big changes to our visual language that would need to be accounted for."
      listTitle2="Development culture."
      listText2="Stash's philosophy generally skewed toward small, lean tests. This was a big bet."
      listTitle3="Existing roadmaps."
      listText3="Every team had a planned roadmap for the quarter, we needed to slot this in somewhere."
    />

    <ProjectSectionLarger
      eyebrow="The solution"
      title="An all-new onboarding experience. 👌"
      text="Doesn't look too shabby either."
      reasonTitle1="New visual design."
      reasonText1="Creating an immediate perception of quality and credibility."
      reasonVideo1={require('../../images/runway-01.mp4').default}
      reasonTitle2="Lively interactions."
      reasonText2="Delight users and positively reinforce behaviors to promote completion."
      reasonVideo2={require('../../images/runway-02.mp4').default}
      reasonTitle3="Reduced copy."
      reasonText3="Only said what we needed to say to reduce cognitive overload in a long flow."
      reasonVideo3={require('../../images/runway-03.mp4').default}
      reasonTitle4="Security driven."
      reasonText4="A greater emphasis placed on gaining user's trust that their information would be secure."
      reasonVideo4={require('../../images/runway-04.mp4').default}
    />

    <ProjectSectionData
      eyebrow="The results"
      numberColor={'green'}
      title="It absolutely crushed it. 🏆"
      text="Arguably the most impactful test in Stash history."
      numberTitle1="+11%"
      numberText1="account creates."
      numberTitle2="+9%"
      numberText2="subscribers."
      numberTitle3="+7%"
      numberText3="contribution."
      number1="1"
      listTitle1="We opened the funnel."
      listText1="An 11.4% lift at the top of the funnel has massive implications. Nearly every metric downstream improved when we gained a greater volume of incoming users."
      number2="2"
      listTitle2="More people in the door."
      listText2="Gaining subscribers opened the door for potential upgrades and upsells later on in user's financial journey. It creates an immediate new source of revenue for the business."
      number3="3"
      listTitle3="Impact to the bottom line."
      listText3="At the end of the day the business needs to make money, and users need to get on the platform. We accomplished both."
    />
  </div>
)

export default runwayPage
