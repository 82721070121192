import React from 'react'
import ProjectTitle from '../ProjectTitle'
import ProjectSection from '../ProjectSection'
import ProjectSectionData from '../ProjectSectionData'
import ProjectSectionParagraphs from '../ProjectSectionParagraphs'
import ProjectSectionLarger from '../ProjectSectionLarger'
import { Slide } from '../styles/Animation'

const prplPage = () => (
  <div>
    <Slide delay={0.1}>
      <ProjectTitle
        image={require('../../images/prpl.png').default}
        title="PRPL 2.0"
        text="Stash's powerful 3-platform design system."
      />
    </Slide>

    <Slide delay={0.2}>
      <ProjectSection
        eyebrow="The problem"
        title="Stash's design system was extremely limited."
        text="We didn't have much to work with."
        reasonTitle1="Lack of foundations."
        reasonText1="Only 12 colors, 8 type styles, and a few components here and there."
        reasonImage1={require('../../images/options.png').default}
        reasonTitle2="Falling behind."
        reasonText2="Many of our major competitiors had been making strong investments in visual design."
        reasonImage2={require('../../images/competition.png').default}
        reasonTitle3="Zero documentation."
        reasonText3="There was constant back and forth between design and eng on how patterns were used."
        reasonImage3={require('../../images/docs.png').default}
        reasonTitle4="Not much of a system."
        reasonText4="Many things weren't resuable, and the component libraries were chaotic."
        reasonImage4={require('../../images/system.png').default}
      />
    </Slide>

    <ProjectSectionData
      eyebrow="Getting started"
      hideProjectSectionNumberGroup="true"
      title="We needed to create a clear path forward."
      text="A system isn't born overnight."
      number1="1"
      listTitle1="Identifying our existing pain points."
      listText1="There was no use creating a new system if we didn't know what was plaguing our existing one. We spent time coming up with the key items we wanted to change."
      number2="2"
      listTitle2="Establishing a new set of principles and foundations."
      listText2="Once we were aligned on what we wanted to change, we set out to construct a new visual language system. A small team of designers spent many nights and weekends perfecting the vision we had for the product."
      number3="3"
      listTitle3="Socializing our proposal."
      listText3="A lot of the changes we were proposing were dramatic departures from our current visual language. We'd need buy-in across the organization if we wanted to make our vision a reality."
    />

    <ProjectSectionParagraphs
      eyebrow="Core themes"
      title="Simplify literally everything."
      text="Less really is more."
      listTitle1="Use color intentionally."
      listText1="Color was used very liberally in our old system, and it was clear that it held no value. We agreed to reserve color for instances where we wanted to cue the user's attention."
      listTitle2="Lean more on spacing."
      listText2="Our existing system built hierarchy through dividers, color blocks, cards, cells, elevation, and more. We wanted to lean more on white-space to let our pages breathe."
      listTitle3="Mimic natural patterns."
      listText3="Things progress from right to left, elevated things have shadows, removing cognitive guess-work will make our system feel choesive with things customers are accustomed to."
    />

    <ProjectSectionLarger
      eyebrow="New foundation"
      title="Color."
      text="Perfectly pristine pallettes."
      reasonTitle1="Two new pallette systems."
      reasonText1="A new 3-color system for use in the product and an extended pallette for brand."
      reasonVideo1={require('../../images/color.mp4').default}
      reasonTitle2="3 theme support"
      reasonText2="Colors were built to scale across three core themes: light, highlight, and midnight."
      reasonVideo2={require('../../images/theme.mp4').default}
      reasonTitle3="Token system."
      reasonText3="A system of global, functional, and component tokens to support scalability."
      reasonImage3={require('../../images/tokens.png').default}
      reasonTitle4="Accessibility first."
      reasonText4="Improved color contrast ratios across all our product experiences."
      reasonImage4={require('../../images/access.png').default}
    />

    <ProjectSection
      eyebrow="New foundation"
      title="Motion."
      text="We like to move it, move it."
      reasonTitle1="Fluid motion system."
      reasonText1="Spring-based interactions for a more unique, natural feel."
      reasonVideo1={require('../../images/fluid.mp4').default}
      reasonTitle2="Static motion system."
      reasonText2="Determined values for duration, easing, and delay."
      reasonVideo2={require('../../images/static.mp4').default}
      reasonTitle3="Microinteractions."
      reasonText3="Subtle feedback confirmation."
      reasonVideo3={require('../../images/micro.mp4').default}
      reasonTitle4="Templated patterns."
      reasonText4="Similar things move the same way."
      reasonVideo4={require('../../images/transition.mp4').default}
    />

    <ProjectSection
      eyebrow="The rest"
      title="Other foundations."
      text="Best of the rest."
      reasonTitle1="Type."
      reasonText1="18 sizes to support mobile and desktop. New styles introduced to give designers more flexibility."
      reasonImage1={require('../../images/font.png').default}
      reasonTitle2="Spacing."
      reasonText2="8 point spacing system to create visual hierarchy between elements."
      reasonImage2={require('../../images/spacing.png').default}
      reasonTitle3="Layout."
      reasonText3="How our pages come together. Different page structures dictate their functions."
      reasonImage3={require('../../images/layout.png').default}
      reasonTitle4="Object styles."
      reasonText4="Elevation, borders, radii, and shadows. How our components present themselves."
      reasonImage4={require('../../images/objStyle.png').default}
    />

    <ProjectSection
      eyebrow="Taking notes"
      title="Documentation."
      text="The source of truth."
      reasonTitle1="Documentation site."
      reasonText1="A place to reference all our usage and technical documentation."
      reasonImage1={require('../../images/site.png').default}
      reasonTitle2="Technical documentation."
      reasonText2="Explicit documentation for engineering handoff."
      reasonImage2={require('../../images/technical.png').default}
      reasonTitle3="Usage documentation."
      reasonText3="Set of guidelines for designers to adhere to."
      reasonImage3={require('../../images/usage.png').default}
      reasonTitle4="Figma organization."
      reasonText4="A collaborative space between design and engineering."
      reasonImage4={require('../../images/fig.png').default}
    />
  </div>
)

export default prplPage
