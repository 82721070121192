import React from 'react'
import styled from 'styled-components'
import ProjectHeader from './ProjectHeader'
import { themes } from './styles/ColorStyles'
import { Body18, Title28 } from './styles/TextStyles'

const ProjectSectionGroup = styled.div`
  padding: 64px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 80px 0px 80px;
  }

  @media (min-width: 2000px) {
    padding: 120px 20%;
  }
`

const Wrapper = styled.div`
  margin-top: 16px;

  @media (max-width: 800px) {
    margin-top: 0px;
  }
`
const Reason = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 64px;
  transition: 400ms;

  @media (max-width: 800px) {
    margin-bottom: 48px;
  }
`

const TextWrapper = styled.div``

const ImgWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  border-radius: 16px;
`

const ReasonTitle = styled(Title28)`
  color: ${themes.light.neutral9};
  margin: 0px;
  transition: 400ms;

  @media (max-width: 800px) {
    font-size: 24px;
  }
`
const ReasonText = styled(Body18)`
  color: ${themes.light.neutral7};
  margin: 16px 0 40px 0;
  font-weight: 400;
  transition: 400ms;

  @media (max-width: 800px) {
    font-size: 18px;
    margin: 16px 0 24px 0;
  }
`
const ReasonVideo = styled.video`
  width: 100%;
  border-radius: 8px;
  transition: 400ms;

  @media (max-width: 800px) {
    height: 210px;
    margin: 16px 0 24px 0;
  }
`

const ReasonImage = styled.img`
  width: 100%;
  border-radius: 8px;
  transition: 400ms;

  @media (max-width: 800px) {
    height: 210px;
    margin: 16px 0 24px 0;
  }
`

const ProjectSectionLarger = (props) => (
  <ProjectSectionGroup>
    <ProjectHeader
      eyebrow={props.eyebrow}
      title={props.title}
      text={props.text}
    ></ProjectHeader>
    <Wrapper>
      <Reason>
        <TextWrapper>
          <ReasonTitle>{props.reasonTitle1}</ReasonTitle>
          <ReasonText>{props.reasonText1}</ReasonText>
        </TextWrapper>
        <ImgWrapper>
          {props.reasonVideo1 ? (
            <ReasonVideo src={props.reasonVideo1} autoPlay loop muted />
          ) : props.reasonImage1 ? (
            <ReasonImage src={props.reasonImage1} />
          ) : null}
        </ImgWrapper>
      </Reason>
      <Reason>
        <TextWrapper>
          <ReasonTitle>{props.reasonTitle2}</ReasonTitle>
          <ReasonText>{props.reasonText2}</ReasonText>
        </TextWrapper>
        <ImgWrapper>
          {props.reasonVideo2 ? (
            <ReasonVideo src={props.reasonVideo2} autoPlay loop muted />
          ) : props.reasonImage2 ? (
            <ReasonImage src={props.reasonImage2} />
          ) : null}
        </ImgWrapper>
      </Reason>
      <Reason>
        <TextWrapper>
          <ReasonTitle>{props.reasonTitle3}</ReasonTitle>
          <ReasonText>{props.reasonText3}</ReasonText>
        </TextWrapper>
        <ImgWrapper>
          {props.reasonVideo3 ? (
            <ReasonVideo src={props.reasonVideo3} autoPlay loop muted />
          ) : props.reasonImage3 ? (
            <ReasonImage src={props.reasonImage3} />
          ) : null}
        </ImgWrapper>
      </Reason>
      <Reason>
        <TextWrapper>
          <ReasonTitle>{props.reasonTitle4}</ReasonTitle>
          <ReasonText>{props.reasonText4}</ReasonText>
        </TextWrapper>
        <ImgWrapper>
          {props.reasonVideo4 ? (
            <ReasonVideo src={props.reasonVideo4} autoPlay loop muted />
          ) : props.reasonImage4 ? (
            <ReasonImage src={props.reasonImage4} />
          ) : null}
        </ImgWrapper>
      </Reason>
    </Wrapper>
  </ProjectSectionGroup>
)

export default ProjectSectionLarger
