import React from 'react'
import styled from 'styled-components'
import { Slide } from './styles/Animation'
import { themes } from './styles/ColorStyles'
import { fontWeight, Title20, Title32 } from './styles/TextStyles'

const HeadWrap = styled.div`
  margin-right: 48px;
  padding-bottom: 40px;

  @media (max-width: 800px) {
    padding-top: 120px;
    padding-bottom: 40px;
  }
`
const Word = styled(Title32)`
  margin: 0px;
  color: ${themes.light.neutral9};
  font-weight: ${fontWeight.extraBold};
`

const Subhead = styled(Title20)`
  margin-top: 24px;
  color: ${themes.light.neutral7};
  font-weight: ${fontWeight.regular};
`

const Headline = (props) => (
  <Slide delay={props.delay}>
    <HeadWrap>
      <Word>{props.text}</Word>
      {props.subhead && <Subhead>{props.subhead}</Subhead>}
    </HeadWrap>
  </Slide>
)

export default Headline
