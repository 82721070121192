import React from 'react'
import styled from 'styled-components'
import '../layouts/index.css'

const Wrap = styled.div`
  padding: 140px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  transform-origin: 50% 20px;

  @media (max-width: 800px) {
    padding: 32px 0px;
  }

`

const Layout = ({ children }) => (
  <Wrap>
    {children}
  </Wrap>
)

export default Layout
