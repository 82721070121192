import React from 'react'
import styled from 'styled-components'
import { themes } from './styles/ColorStyles'
import { fontWeight, Title20, Title48 } from './styles/TextStyles'

const COLORS = { green: '#1CE0A5', red: '#DE2D68' }

const Wrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f0f0f5;
  margin-right: 24px;
  border-radius: 12px;
  padding: 32px;
  transition: 400ms;

  &:hover {
    background-color: ${themes.light.neutral4};
  }

  @media (max-width: 800px) {
    padding: 24px;
    margin-bottom: 16px;
    margin-right: 0px;
  }
`

const ProjectSectionNumberTitle = styled(Title48)`
  color: ${(props) => COLORS[props.color]};
  margin: 0 0 12px 0;
  text-align: center;
  font-weight: ${fontWeight.extraBold};
  transition: 400ms;

  @media (max-width: 800px) {
    font-size: 40px;
  }
`

const ProjectSectionNumberText = styled(Title20)`
  margin: 0 auto;
  color: ${themes.light.neutral7};
  font-weight: ${fontWeight.medium};
  text-align: center;
  transition: 400ms;
`

const ProjectSectionNumber = (props) => (
  <Wrap>
    <ProjectSectionNumberTitle color={props.color}>
      {props.title}
    </ProjectSectionNumberTitle>
    <ProjectSectionNumberText>{props.text}</ProjectSectionNumberText>
  </Wrap>
)

export default ProjectSectionNumber
