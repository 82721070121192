import React from 'react'
import Link from 'gatsby-link'
import Headline from '../Headline'
import styled from 'styled-components'
import Container from '../Container'
import CardMin from '../CardMin'

const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
`

const workPage = () => (
  <Container>
    <Headline text="Samples of work that I'm proud of." delay="0.1" />
    <CardWrap>
      <Link to="/work/runway">
        <CardMin
          title="Onboarding"
          company="Stash"
          middle="·"
          year="2020"
          icon={require('../../images/icon/arrow.svg').default}
          delay="0.13"
        />
      </Link>
      <Link to="/work/browse">
        <CardMin
          title="Browse"
          company="Peloton"
          middle="·"
          year="2021"
          icon={require('../../images/icon/arrow.svg').default}
          delay="0.15"
        />
      </Link>
      <Link to="/work/prpl">
        <CardMin
          title="PRPL 2.0"
          company="Stash"
          middle="·"
          year="2020"
          icon={require('../../images/icon/arrow.svg').default}
          delay="0.17"
        />
      </Link>
    </CardWrap>
  </Container>
)

export default workPage
