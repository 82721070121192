import React from 'react'
import styled from 'styled-components'
import ProjectHeader from './ProjectHeader'
import { themes } from './styles/ColorStyles'
import { Body18, Title28 } from './styles/TextStyles'

const ProjectSectionGroup = styled.div`
  padding: 64px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 80px 0px 80px;
  }

  @media (min-width: 2000px) {
    padding: 120px 20%;
  }
`

const ListCopy = styled.div`
  display: flex;
  flex-direction: column;
  transition: 400ms;
  width: 100%;

  @media (max-width: 800px) {
    padding-top: 40px;
  }
`

const ListWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;

  & ${ListCopy}:nth-child(1) {
    padding-right: 40px;
  }

  & ${ListCopy}:nth-child(2) {
    padding-right: 40px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`

const ListTitle = styled(Title28)`
  color: ${themes.light.neutral9};
  margin: 0;
  transition: 400ms;
`

const ListText = styled(Body18)`
  color: ${themes.light.neutral7};
  margin: 16px 0 0 0;
  font-weight: 400;
  transition: 400ms;
`

const ProjectSectionParagraphs = (props) => (
  <ProjectSectionGroup>
    <ProjectHeader
      eyebrow={props.eyebrow}
      title={props.title}
      text={props.text}
    ></ProjectHeader>
    <ListWrapper>
      <ListCopy>
        <ListTitle>{props.listTitle1}</ListTitle>
        <ListText>{props.listText1}</ListText>
      </ListCopy>

      <ListCopy>
        <ListTitle>{props.listTitle2}</ListTitle>
        <ListText>{props.listText2}</ListText>
      </ListCopy>

      <ListCopy>
        <ListTitle>{props.listTitle3}</ListTitle>
        <ListText>{props.listText3}</ListText>
      </ListCopy>
    </ListWrapper>
  </ProjectSectionGroup>
)

export default ProjectSectionParagraphs
