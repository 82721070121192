import React from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../components/PrivateRoute'
import workPage from '../components/work'
import prplPage from '../components/work/prpl'
import browsePage from '../components/work/browse'
import runwayPage from '../components/work/runway'
import Layout from '../components/Layout'

const Work = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/work" component={workPage} />
      <PrivateRoute path="/work/prpl" component={prplPage} />
      <PrivateRoute path="/work/runway" component={runwayPage} />
      <PrivateRoute path="/work/browse" component={browsePage} />
    </Router>
  </Layout>
)

export default Work

