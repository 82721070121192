import React from 'react'
import styled from 'styled-components'
import { Slide } from './styles/Animation'
import { themes } from './styles/ColorStyles'
import { fontWeight, Body16, Title24 } from './styles/TextStyles'

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: 0;
  transition: 300ms;
  align-items: center;
  width: 316px;
  padding: 16px 32px 16px 0px;

  &:hover {
    width: 328px;
  }

  &:hover .hov {
    color: ${themes.light.neutral9};
  }

  &:active {
    transform: scale(0.98);
  }

`

const Copy = styled.div`
  display: flex;
  flex-direction: column;
  transition: 300ms;
  width: 100%;

`

const Title = styled(Title24)`
  color: ${themes.light.neutral9};
  margin: 0px 0px 4px 0px;
  font-weight: ${fontWeight.bold};

  @media (max-width: 800px) {
    font-size: 24px;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: row;
`

const Text = styled(Body16)`
  color: ${themes.light.neutral7};
  margin-right: 8px;
  font-weight: ${fontWeight.thin};
  transition: 300ms;

  @media (max-width: 800px) {
    font-size: 18px;
  }
`
const Icon = styled.img`
  width: 32px;
  height: 32px;
`

const CardMin = (props) => (
  <Slide delay={props.delay}>
    <Wrap>
      <Copy>
        <Title>{props.title}</Title>
        <TextWrap>
          <Text className="hov">{props.company}</Text>
          <Text className="hov">{props.middle}</Text>
          <Text className="hov">{props.year}</Text>
        </TextWrap>
      </Copy>
      <Icon src={props.icon} />
    </Wrap>
  </Slide>
)

export default CardMin
