import React from 'react'
import styled from 'styled-components'
import ProjectHeader from './ProjectHeader'
import ProjectSectionNumber from './ProjectSectionNumber'
import { Body18, fontWeight, Title24, Title28 } from './styles/TextStyles'
import { themes } from './styles/ColorStyles'

const ProjectSectionGroup = styled.div`
  padding: 64px 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    padding: 80px 0px 80px;
  }

  @media (min-width: 2000px) {
    padding: 120px 20%;
  }
`

const ProjectSectionNumberGroup = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  margin-top: 16px;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 80%;
  }
`
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const List = styled.div`
  display: flex;
  margin-top: 56px;
  transition: 400ms;

  &:hover {
  }

  &:hover .NumWrapper {
    background-color: ${themes.light.neutral4};
  }

  @media (max-width: 800px) {
    flex-direction: column;
    margin-top: 16px;
    align-items: flex-start;
  }
`
const NumWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${themes.light.neutral3};
  border-radius: 90px;
  height: 64px;
  transition: 400ms;

  @media (max-width: 800px) {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
    margin-top: 40px;
  }
`

const Num = styled(Title24)`
  color: ${themes.light.neutral9};
  width: 64px;
  margin: 0 auto;
  text-align: center;
  transition: 400ms;

  @media (max-width: 800px) {
    width: 40px;
  }
`

const ListCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;

  @media (max-width: 800px) {
    padding-left: 0px;
  }
`

const ListTitle = styled(Title28)`
  color: ${themes.light.neutral9};
  margin: 0;
  transition: 400ms;
`

const ListText = styled(Body18)`
  color: ${themes.light.neutral7};
  margin: 16px 0 0 0;
  font-weight: ${fontWeight.thin};
  transition: 400ms;
`

const ProjectSectionData = (props) => (
  <ProjectSectionGroup>
    <ProjectHeader
      eyebrow={props.eyebrow}
      title={props.title}
      text={props.text}
    ></ProjectHeader>
    {!props.hideProjectSectionNumberGroup ? (
      <ProjectSectionNumberGroup>
        <ProjectSectionNumber
          title={props.numberTitle1}
          text={props.numberText1}
          color={props.numberColor}
        ></ProjectSectionNumber>
        <ProjectSectionNumber
          title={props.numberTitle2}
          text={props.numberText2}
          color={props.numberColor}
        ></ProjectSectionNumber>
        <ProjectSectionNumber
          title={props.numberTitle3}
          text={props.numberText3}
          color={props.numberColor}
        ></ProjectSectionNumber>
      </ProjectSectionNumberGroup>
    ) : null}
    <ListWrapper>
      <List>
        <NumWrapper className="NumWrapper">
          <Num>{props.number1}</Num>
        </NumWrapper>
        <ListCopy>
          <ListTitle>{props.listTitle1}</ListTitle>
          <ListText>{props.listText1}</ListText>
        </ListCopy>
      </List>
      <List>
        <NumWrapper className="NumWrapper">
          <Num>{props.number2}</Num>
        </NumWrapper>
        <ListCopy>
          <ListTitle>{props.listTitle2}</ListTitle>
          <ListText>{props.listText2}</ListText>
        </ListCopy>
      </List>
      <List>
        <NumWrapper className="NumWrapper">
          <Num>{props.number3}</Num>
        </NumWrapper>
        <ListCopy>
          <ListTitle>{props.listTitle3}</ListTitle>
          <ListText>{props.listText3}</ListText>
        </ListCopy>
      </List>
    </ListWrapper>
  </ProjectSectionGroup>
)

export default ProjectSectionData
