const isBrowser = typeof window !== `undefined`

const getUser = () =>
  window.localStorage.gatsbyUser
    ? JSON.parse(window.localStorage.gatsbyUser)
    : {}

const setUser = (user) =>
  (window.localStorage.gatsbyUser = JSON.stringify(user))

export const handleLogin = (password) => {
  if (!isBrowser) return false

  if (password === `lobster`) {
    console.log(`Wow nice job, that's the correct password.`)
    return setUser({
      name: `guest`,
      authed: true,
    })
  }

  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()

  return user.authed
}
